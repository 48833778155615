<template>
    <div class="warper">
        <div class="headers" ref="titleH">
            <span @click="goUp()"></span>
            <span>竞价详情</span>
            <span></span>
        </div>
        <div class="bidding" >
            <div class="bidding-content" ref="contentRef">
                <div class="bidding-content-img" v-if="message.photo">
                    <div class="img-tag">
                        <p v-if="message.Status === 1">未开始</p>
                        <p v-if="message.Status === 2">竞拍中</p>
                        <p v-if="message.Status === 3">已结束</p>
                        <p v-if="message.Status === 4">已中标</p>
                        <p v-if="message.Status === 5">已撤销</p>
                        <p v-if="message.Status === 6">已流拍</p>
                        
                        <p>{{message.sales_num}}人出价</p>
                    </div>
                    <img class="imgs" :src="photo(message.photo)" alt="">
                </div>
                <p class="bidding-content-p">
                    <span class="left" style="font-size: 17px;font-weight: bold;">{{message.title}} <span v-if="message.relevance == 1">（{{message.num}} {{t}}）</span></span>
                    <span class="right">
                       <span>倒计时：</span>
                       <van-count-down :time="message.Stamp * 1000">
                            <template #default="timeData">
                                <span class="block">{{ timeData.days }}</span>
                                <span class="colon">天</span>
                                <span class="block">{{ timeData.hours }}</span>
                                <span class="colon">时</span>
                                <span class="block">{{ timeData.minutes }}</span>
                                <span class="colon">分</span>
                            </template>
                        </van-count-down>
                    </span>
                </p>
                <p class="bidding-content-p choazhu" style=" margin: 10px 0;">
                    <!-- {{message.describe}} -->
                </p>
                <p  class="bidding-content-p">
                    <span  style="font-size: 18px;">起拍价格：</span>
                    <span v-if="mold ==1 " style="color:red;font-size: 18px;"> ￥{{isprice(message.original_price)}}</span>
                    <span v-if="mold !=1 " style="font-size: 18px;"> ￥{{isprice(message.original_price)}}</span>
                     <a  class="bid" :href="'tel:' +message.tel">
                        <van-icon name="phone-o" />
                    </a>
                </p>
                <p class="bidding-content-p" v-if="mold !=1 " >
                     <span style="font-size: 15px;">我的出价：</span>  
                    <span style="font-size: 15px;color:red;">￥{{message.wodechujai}}</span>
                </p>
            </div>
            <div class="specification">
                 <p class="specification-title">
                     竞拍备注
                </p>
                 <hr>
                <p style="text-indent: 2em;">
                    {{message.describe}}
                </p>
            </div>
            <div v-if="message.cidArr">
                <div class="specification" v-if="message.cidArr.length>0">
                    <p class="specification-title">
                        回用件列表
                    </p>
                    <hr>
                    <p style="text-indent: 2em;">
                        <div v-for="(item,index) in message.cidArr">
                            <van-row gutter="20" :style="{marginTop:'20px'}">
                                <van-col span="8">
                                    <van-image
                                    width="100%"
                                    height="6em"
                                    :src="item.image.split(',')[0]"/>
                                </van-col>
                                <van-col span="16">
                                    <div :style="{marginTop:'0em'}" class="text-overflow">进场编码：{{item.parts_id}}</div>
                                    <div :style="{marginTop:'0.5em'}" class="text-overflow">配件名称：{{item.patrsName}}</div>
                                    <div :style="{marginTop:'0.5em'}" class="text-overflow">车辆品牌：{{item.vehicleBrand}}</div>
                                    <div :style="{marginTop:'0.5em'}" class="text-overflow">竞价数量：{{item.num}}</div>
                                </van-col>
                                
                            </van-row>
                        </div>
                    </p>
                </div>
            </div>
            
            <div class="specification" ref="clsHeight" v-if="message.content">
                <p class="specification-title">
                    详情介绍
                </p>
                 <hr>
                <div class="ovh">
                    <p  id="pics-p"  v-html="message.content"></p>
                </div>
            </div>
            <div class="specification"   v-show="message.other_price_json.length!=0">
                <div v-for="(item,index) in message.other_price_json" :key="index">
                    <p class="specification-title">
                        {{item.name}}
                    </p> 
                    <hr>
                  
                    <p class="otherCsizep"> <span class="jiacu">费用金额：</span> {{item.value}} {{item.type== 1 ? '元' :'%'}}</p>
                    
                    <p class="otherCsizep" v-if="item.remark"><span class="jiacu">费用说明：</span>{{item.remark}}</p>
                    <p class="otherCsizep" v-if="item.phone"><span class="jiacu">付款联系电话：</span>{{item.phone}}  
                        <a  class="bid" :href="'tel:' + item.phone">
                            <van-icon name="phone-o" />
                        </a>
                    </p>
                   
                 </div>
            </div>
          
            <div v-if="mold==1" class="specification " style="overflow: hidden;" ref="payRef">
                
                <p class="specification-title">
                    出价详情
                </p>
                <hr>
                <div class="fl_box">
                    <div class="leftc">
                        <p v-if="message.is_increment == 1"> <span class="jiacu"></span>  最低加价幅度：<span>{{returnFloat(Number(message.increment))}}元</span> </p>
                        <p v-if="message.top_price==1 && message.wodechujai != 0"> <span class="jiacu">当前最高报价：</span> <span>{{returnFloat(Number(message.maxPrice))}}元</span> </p>
                        <p> <span class="jiacu">我的当前报价：</span> <span>{{returnFloat(Number(message.wodechujai))}}元</span> </p>
                        <div v-if="message.wodechujai">
                            <div v-for="(item,index) in message.other_price_json" >
                                <p > <span class="jiacu">{{item.name}}：</span>   {{Ispic(item)}}</p>
                            </div>
                        </div>
                        <p> <span class="jiacu">总费用：</span> <span style="color:red">{{returnFloat(Number(totalPrices))}}</span>元</p>
                    </div>
                
                    <div class="rightc" v-if="message.top_price==1 && message.is_increment == 1 && message.now_status != 0 && message.wodechujai">
                        <span class="btnsass"  @click="AKeyBid">一键加价<br> <span class="hps">（+{{message.increment}}）</span></span>
                    </div>
                </div>
                   
                <br>
               
             
                <!-- <p>追加出价：<span>{{message.maxPrice}}</span> </p>
                <p>追加后报价：<span>{{message.maxPrice}}</span> </p> -->
            </div>
           
        </div>
        <div class="btn-right" ref="btnRef" v-if="mold==1">
            <!-- <van-button type="info" @click="rightAwayBid">马上出价</van-button> -->
            <van-button class="btn-right-p" v-if="message.now_status == 0" @click="register">报名</van-button>
      
            <van-button class="btn-right-p" v-if="message.now_status == 1" @click="lodData">审核中</van-button>
            <van-button class="btn-right-p" v-if="message.now_status == 4 || message.now_status == 2" @click="rightAwayBid">马上出价</van-button>
            <van-button class="btn-right-p" v-if="message.now_status == 3" >审核拒绝</van-button>
            <van-button class="btn-right-p" v-if="message.now_status == 5" @click="tishi_show = true">未开拍</van-button>
        </div>
         <van-popup  closeable  v-model="tishi_show" class="popup" :close-on-click-overlay="false">
            <h3 style="text-align: center; ">提示</h3>
            <p>竞价开始时间：{{message.starttime}}</p>
            <p>竞价开始结束：{{message.endtime}}</p>
            <div style="text-align: center;margin:10px 0 ">

                <van-button style="width:200px" type="info" @click="tishi_show = false">确定</van-button>
            </div>
        </van-popup>
         <van-popup  closeable  v-model="is_show" class="popup" :close-on-click-overlay="false">
            <cashDeposit ref="posit" @application="application" />
        </van-popup>
         <van-popup  :closeable="true"  v-model="shows" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">温馨提示</p>
              <hr>
              <p style="text-indent: 2em;padding: 10px;"> 您当前没有{{title}}竞价资格，无法查看竞价详细信息。</p>
              <hr>
              <div class="btn-ok">
                  <!-- <van-button @click="shows= false">取消</van-button> -->
                  <!-- <p class="btn-ok-p" @click="goApply">申请竞价资格</p> -->
                  <van-button type="info" @click="goApply">申请竞价资格</van-button>
              </div>
        </van-popup>
          <van-popup  closeable  v-model="show" class="popup" :close-on-click-overlay="false">
               <van-form class="popup-form" @submit="onSubmit( $event,pic)">
                    <van-field
                        v-model="pic"
                        name="my_price"
                        :label="look==1?'再次出价':'竞拍价格'"
                        placeholder="请输入竞拍价格"
                        :rules="[{ required: true, message: '请填写竞拍价格' },{pattern,message: '请输入正确价格'}]"
                    />
                   
                    <van-field
                        v-model="username"
                         name="uname"
                        label="姓名"
                        placeholder="请输入姓名"
                    />
                    <van-field
                        v-model="phone"
                        name="mobile"
                        label="联系方式"
                        placeholder="请输入联系方式"
                    />
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">提交</van-button>
                    </div>
                </van-form>
        </van-popup>
    </div>
</template>

<script>
import {deleteCookie} from '../../utils/env'
import { isShouQuans,NO1ShouQuans } from '../../utils/impower.js'
import { micro_post } from "../../Http/api.js";
import { Toast, Notify,Dialog } from 'vant';
import cashDeposit from './components/cashDeposit.vue'
    export default {
          metaInfo(){
            return {
                 title: this.$route.query.shopId == '1078' ? '移动端拆船件' :    window.localStorage.getItem('getShopName')
            }
        },
        components: {
            cashDeposit,
        },
        data() {
            return {
                time: 3 * 60 * 60 * 1000,
                message:{other_price_json:[]},
                show:false,
                shows:false,
                is_show:false,
                tishi_show:false,
                pic:"",
                username:"",
                phone:"",
                type:null,
                pattern:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
                mold:1,
                ids:null,
                title:'',
                clientHeight:0
            }
        },
   
        async created () {
        
            this.mold = this.$route.query.mold?this.$route.query.mold:1
            let ShouQuan =  window.localStorage.getItem('isShouQuan')

          
               const toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                await NO1ShouQuans(this)
                toast.clear()//清除加载效果
            
            this.lodData();
            this.getnamePhone()
            this.ids = this.$route.query.type
            switch (this.ids) {
                case '1':
                    this.title = "大宗竞价资格申请";
                    break;
                case '2':
                    this.title = "大宗回用件竞价";
                    break;
                case '3':
                    this.title = "回用件竞价资格申请";
                    break;
                default:
                    break;
            }
        },
        computed: {
            t() {
                let name = ''
                if(this.$route.query.type == 1 ){
                    name= 'Kg'
                }else if(this.$route.query.type == 3){
                    name = '件'
                
                }else if(this.$route.query.type == 2){
                    name = '辆'
                }
                return name
            },
            look() {
                return this.$route.query.look || ''
            },
             photo() {
                return (e)=>{
                
                    let arr =[]
                    if(e){
                        arr  = e.split(',')
                    }else{
                        return false
                    }
                    return arr[0]
                }
                
            },
            isprice() {
               return ((e)=>{
                
                   return Number(e).toFixed(2)
               })
            },
            OtherPic(){
                return ((e)=>{
                    let s1 = ''
                    let s2 = ''
                    let s3 = ''
                    let s4 = ''
                    let sun = null
                    let s5 = null
                this.message.other_price_json.forEach(i => {
                    if(i.type == 1){
                        s1 =  this.returnFloat( Number(i.value ))
                        s3 =  s3 + s1 +'<span style="color:#000"> 元' + i.name +' </span>' + '、'
                    }else if(i.type==2){
                        s2 = this.returnFloat(Number(e ) *  Number((i.value*1000) / (100*1000)) )
                        s4 = s4 +  s2 + '<span style="color:#000"> 元' + i.name + ' </span>'+'、'
                    }
                });
                    sun =this.returnFloat(( Number(e *1000) + (s1*1000) + (s2*1000))/1000)
                    s5 = `<span> 您本次出价为<span style="color:red"> ${this.returnFloat(e)}</span>，<span style="${this.message.other_price_json.length!=0 ?'':'display: none;'}" ><br><span  style="color:#000;">叠加</span><span style="color:blue !important;">${s3}</span><br><span style="color:blue">${s4}</span></span>总价为<span style="color:red">${sun}元</span> ，<br>确定出价吗?</span>`
                
                return s5
                })
            },
            Ispic(){
                return ((e)=>{
                    let str = ''
                    let num = 0
                    if(e.type==1){
                        str = this.returnFloat(Number(e.value).toFixed(2)) + '元'
                    }else {
                       num = (e.value*1000) /(100*1000)
                       str = this.returnFloat(parseFloat(this.message.wodechujai) * num)  + '元'
                     
                    }
                    return str
                })
            },
            totalPrices(){
                   let sun1 = 0
                   let sun2 = 0
                   let num = 0
                   let bi = 0
                    this.message.other_price_json.forEach((i)=>{
                        if(i.type==1){
                            sun1 += Number(this.returnFloat(Number(i.value).toFixed(2)))
                           
                        }else{
                            bi = (i.value*1000) /(100*1000)
                            sun2 += Number(this.returnFloat(parseFloat(this.message.wodechujai) * bi) )
                        }
                    })
                    num = (Number(this.returnFloat(parseFloat(sun1)))*1000 + Number(this.returnFloat(parseFloat(sun2)))*1000 + Number(this.returnFloat(parseFloat(this.message.wodechujai)))*1000)/1000
                   
                return num
            }
        },
        methods: {
            //报价弹窗
            rightAwayBid(){
                this.show = true
                this.pic = ''
         
            },
            getnamePhone(){
                 const data = {
                    url: "/index.php/BidManagement/ensure_order",
                    params: {id:this.$route.query.id,shopId:this.$route.query.shopId,type:this.$route.query.type},
                    success: (data) => {
                        if(data.data){
                            this.username = data.data.uname
                            this.phone = data.data.mobile
                        }
                    },
                };
                micro_post(data,this);
            },
            //报名弹窗
             register(){
                if(this.message.is_money == 1) {
                    this.is_show = true
                    this.$nextTick(()=>{
                        this.$refs.posit.getInfo(this.message)
                    })
                }else{
                    const data =  {
                        url: "index.php/BidManagement/bidSignOrder",
                        params: { shopId:this.$route.query.shopId,type:this.$route.query.type,act_id:this.message.id },
                        success:  (res) => {
                            this.$toast.fail(res.msg)
                            this.lodData()
                        },
                    };
                     micro_post(data,this);
                }
            },
            application(){
                this.is_show = false
                this.lodData()
            },
            //返回列表
            goUp() {
                this.$router.push({path:'/BiddingList',query:{shopId:this.$route.query.shopId,type:this.$route.query.type}})
            },
            //带电话
            callPhone(phone){
                window.open(`tel:${phone}`, '_blank')
            },
            //申请竞价资格
            goApply(){
                 this.$router.push({path:'/QualificationApplication',query:{shopId:this.$route.query.shopId,type:this.$route.query.type}})
            },
            //一键报价
            AKeyBid(){
                let sun = (parseFloat(this.message.increment)*1000/1000) + (parseFloat(this.message.wodechujai)*1000/1000)
             
             if(this.username && this.phone){
                let obj = {
                    my_price:sun,
                    uname:this.username,
                    mobile:this.phone
                }
                this.onSubmit(obj,sun)
             }else{
                this.show = true
             }
                
            },
            //提交报价
            onSubmit(values,sun) {
                values.order_type = this.type
                values.act_id = this.$route.query.id
                values.shopId = this.$route.query.shopId
                // 您本次出价为${this.returnFloat(this.pic)}15000元，叠加6000元拆解费、500元信息技术服务费，总价为21500元，确定出价吗?
                Dialog.confirm({
                title: '报价确认',
                message: `${this.OtherPic(sun)}`,
                theme: 'round-button',
                }).then(() => {
                  
                    this.$http.post('/index.php/BidManagement/bid_order',values).then(res=>{
                        if(res.data.code == 0){
                            this.show = false
                            this.lodData()
                            this.$toast.success(res.data.msg)
                        }else if(res.data.code == 1){
                            this.show = false
                            this.shows = true
                            this.$toast.fail(res.data.msg)
                        }else{
                            // this.$toast.fail(res.data.msg)
                            alert(res.data.msg)
                        }
                    })
                })
                .catch(() => {
                       this.show = false
                });
            },
             zhuan(url){
                let str = url
                let str1 = str.replace('https','')
                str1 = str1.replace('http','')
                let str2 = 'https'+ str1
                return str2
            },
            //获取详情
            lodData(){
               const data =  {
                    url: "/index.php/BidManagement/bid_info",
                    params: {id:this.$route.query.id,shopId:this.$route.query.shopId},
                    success: (data) => {
                        this.message = data.data
                        this.type = data.data.type
                    },
                    
                };
                micro_post(data,this);
           },
        },
    }
</script>

<style lang="scss" scoped>
.warper{
}
p{
    margin: 10px 0;
    line-height: 24px;
}
 .colon {
    display: inline-block;
    margin: 0 4px;
    color: #ee0a24;
  }
  .block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #ee0a24;
  }
 .headers {
     position: fixed;
     top: 0;left: 0;
     width: 100%;
     z-index: 99;
      padding: 0 20px;
      background-color: cadetblue;
      height: 1rem;
      line-height: 1rem;
      color: white;
      font-size: 0.4rem;
      display: flex;

      justify-content: space-between;
      span {
        display: inline-block;
      }
      span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
      }
        span:nth-child(2) {
        position: absolute;
        left: 41%;
      }
    }
    .bidding{
        padding: 60px 10px 10px;
         height: 100%;
        // min-height: 100vh;
        background-color: #eee;
        .bidding-content{
            width: calc(100% - 20px);
            padding: 10px;
            background-color: white;
            .bidding-content-img{
                width: 100%;
                height: 230px;
                position: relative;
                .imgs{
                    width: 100%;
                    height: 100%;
                }
                .img-tag{
                    position: absolute;
                    top: 0;
                    right: 10%;
                    width: 100px;
                    height: 70px;
                    text-align: center;
                    color: #fff;
                    background-color: #008eff;
                }
            }
            .bidding-content-p{
                width: calc(100% - 40px);
                height: 20px;
                line-height: 20px;
                display: block;
                 padding: 0 20px;
                
                .right{
                    float: right;
                    display: flex;
                    margin: 10px 0;
                }
            }
            .choazhu{
                width: 60%;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
                 word-wrap: break-word;
            }
            .left{
                float: left;
                
            }
           
        }
    }
.bid{
    padding: 5px 10px;
    background-color: #008eff;
    color: white;
    cursor: pointer;
    float: right;
    border-radius: 8px;
    font-size: 20px;
}
    .specification{
        padding: 10px;
        margin: 20px 0;
       
        width: calc(100% - 20px);
        background-color: white;
        // .ovh{
        //     overflow-y: scroll;
        // }
        .specification-title{
            padding: 5px;
            font-size: 22px;
            font-weight: bold;
        }
        .specification-table{
            width: 100%;
            .content-tr{
                text-align: center;
                padding: 5px;
            }
            tr{
                line-height: 0.8rem;
                box-sizing: border-box;
            }
        }
        .img{
            width: 100%;
            height: 230px;
        }
        .otherCsizep{
            padding-left: 20px;
           
        }
    }
   .btn-right{
      
       .btn-right-p{
           
            width: 100%;
            height: 50px;
            color:#fff;
            background-color: #008eff;
            text-align: center;
            line-height: 50px;
            margin-bottom: 35px;
       }
   }
   .popup{
    width: 80%;
    padding: 20px;
    .popup-form{
        padding: 20px;
        
        margin-top: 20px;
    }
}

  
.jiacu{
 font-weight: bold;
}

.fl_box{
     display: flex;
    .leftc{
        width: 70%;
    }
    .rightc{
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.btnsass{
    padding: 10px;
    display: inline-block;
    width: 100%;
    background: #008eff;
    text-align: center;
    margin-right: 10px;
    color: #fff;
    border-radius: 8px;
   .hps{
    
    display: inline-block;
    margin-top: 5px;
   }
}
</style>
<style scoped>
#pics-p{
    word-wrap: break-word;
    overflow-y: scroll;
}
 #pics-p>>>img{
    max-width: 100%;
    /* height: 100%; */
}
 /* #pics-p>>>table tr td{
    border: 1px solid #000;
} */
.sizep{
    padding-left: 20px;
    line-height: 24px;
    
}
.text-overflow{

    overflow:hidden;

    white-space: nowrap;

    text-overflow: ellipsis;

    -o-text-overflow:ellipsis;

}
</style>